/* eslint-disable */
import {nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from '../../models/filtro/filtro'
import {Atualizacao} from '../../models/atualizacao'

const url = 'auxs_data'

const state = {
  atualizacao: new Atualizacao(),
  filtro: new Filtro(),
}

const getters = {
  getAtualizacao: (state) => {
    return state.atualizacao
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}
const actions = {
  setAtualizacao({commit}, licenca) {
    commit('SET_ATUALIZACAO', licenca)
  },
  async processarAtualizacao({commit}, {onSucesso,onErro}) {
    try {

      let res = await processar(url+'/commit', state.atualizacao, 'gravar')
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },

}

const mutations = {
  SET_ATUALIZACAO(state, atualizacao) {
    state.atualizacao= atualizacao
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
