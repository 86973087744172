/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado, processaArquivo} from '../../services/GenericAPI'
import {BackupSCH, getBackupSCH} from "../../search/BackupSCH"
import {Filtro} from "../../models/filtro/filtro";
import {Backup} from '../../models/backup'
import {EventBus} from '../../helpers/event-bus'
import {RelatorioVersaoSCH} from '../../search/RelatorioVersaoSCH'

const url = '/backup'

const state = {
  all: [],
  allList: [],
  allClientesBackup: [],
  backup: new Backup(),
  filtro: new Filtro()
}

const getters = {
  listaBackupCli: (state) => {
    return state.allList
  },
  listaBackups: (state) => {
    return state.all
    // function ordenarListaAsc(lista, chave, tipo = 'asc') {
    //   return lista.sort(function (a, b) {
    //     if (tipo === 'asc') {
    //       if (a[chave] > b[chave]) {
    //         return 1;
    //       }
    //       if (a[chave] < b[chave]) {
    //         return -1;
    //       }
    //     } else {
    //       if (a[chave] < b[chave]) {
    //         return 1;
    //       }
    //       if (a[chave] > b[chave]) {
    //         return -1;
    //       }
    //     }
    //     return 0;
    //   })
    // }
    // if (state.all.length > 0){
    //   return ordenarListaAsc(state.all, 'dt_updated', 'desc');
    // } else {
    //   return state.all
    // }
  },
  listaClientesBackups: (state) => {
    function ordenarListaAsc(lista, chave, tipo = 'asc') {
      return lista.sort(function (a, b) {
        if (tipo === 'asc') {
          if (a[chave] > b[chave]) {
            return 1;
          }
          if (a[chave] < b[chave]) {
            return -1;
          }
        } else {
          if (a[chave] < b[chave]) {
            return 1;
          }
          if (a[chave] > b[chave]) {
            return -1;
          }
        }
        return 0;
      })
    }

    if (state.allClientesBackup.length > 0){
      return ordenarListaAsc(state.allClientesBackup, 'dt_updated', 'desc');
    } else {
      return state.allClientesBackup
    }


  },
  pegaBackup: (state) => {
    return state.backup
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['cnpj_cpf','razao'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getItem({commit},{disparar,filtro,onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_backup', filtro)).data
      commit('SET_BACKUPS', list.data)
      onSucesso(list.data)
    } catch (error) {
      console.log(error)
      if(disparar){
        this.dispatch('geral/setObjMsgError', error.response.data)
      }
      nao_autorizado(error)
      onErro()
    }
  },

  async getBackupItem({commit},{disparar,filtro,onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_backup', filtro)).data
      commit('SET_ALL_LIST', list.data)
      onSucesso(list.data)
    } catch (error) {
      console.log(error)
      if(disparar){
        this.dispatch('geral/setObjMsgError', error.response.data)
      }
      nao_autorizado(error)
      onErro()
    }
  },

  setFiltro({commit}, filtro) {
    commit('SET_FILTRO',  BackupSCH('*'))
  },

  async setAllBackups({commit},array) {

    commit('SET_BACKUPS', array)
  },
  async setAllClientesBackups({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_clientes', state.filtro)).data
      // console.log('SET_CLIENTES_BACKUPS:', list.data)
      commit('SET_CLIENTES_BACKUPS', list)
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async processarDownload({commit}, {prefix, onSucesso, onErro}) {
    try {
      // let retorno = await processaArquivo(url+'/get_download', prefix)
      let retorno = await pegaDados(url+'/get_download', prefix)
      onSucesso(retorno)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },

  setBackup({commit}, backup) {
    commit('SET_BACKUP', backup)
  },
}

const mutations = {
  SET_ALL_LIST(state, allList) {
    state.allList = allList
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_BACKUPS(state, backups) {
    state.all = backups
  },
  SET_CLIENTES_BACKUPS(state, backups) {
    state.allClientesBackup = backups
  },
  SET_BACKUP(state, backup) {
    state.backup = backup
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
